import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    KEYS = {
        ACCESS_TOKEN: 'ACCESS_TOKEN',
        ROUTING_DATA_TYPE: 'ROUTING_DATA_TYPE',
        ROUTING_DATA: 'ROUTING_DATA',
    };

    public hasData(key: string): boolean {
        const encodedKey = btoa(key);
        return localStorage.getItem(encodedKey) !== null;
    }

    public getData(key: string): any {
        const encodedKey = btoa(key);
        const encodedData = localStorage.getItem(encodedKey);

        if (encodedData) {
            const decodedData = atob(encodedData);

            try {
                return JSON.parse(decodedData);
            } catch (e) {
                return decodedData;
            }
        } else {
            return undefined;
        }
    }
    public setData(key: string, value: any): void {
        const encodedKey = btoa(key);

        localStorage.setItem(encodedKey, typeof value === 'string' ? btoa(value) : btoa(JSON.stringify(value)));
    }

    public removeData(key: string): void {
        const encodedKey = btoa(key);
        localStorage.removeItem(encodedKey);
    }

    public clearAll(): void {
        localStorage.clear();
    }

    get user() {
        return this.getData('user');
    }

    get role() {
        return this.user?.role?.level;
    }

    get username() {
        return this.user?.username;
    }

    get permissions() {
        if (this.user) {
            return new Set([...this.user.role.permissions.map((p) => p.key), ...this.user.permissions.map((p) => p.key)]);
        }
        return new Set([]);
    }
}
